(function (doc, el) {
  var loadEmbeds = () => {
    document.querySelectorAll('.shoutout-form').forEach((embed, idx) => {
      var formId = embed.getAttribute('data-formId');
      var loaded = embed.getAttribute('data-loaded');
      if(!formId){
        formId = embed.getAttribute('data-form');
      };
      if (!loaded) {
        embed.setAttribute('data-loaded', true);
        var i = document.createElement('IFRAME');
        var id = `shoutout-form-${formId}-${idx}`;
        var src = `https://shoutout.io/embed/form/${formId}`;
        i.setAttribute('src', src);
        i.setAttribute('frameborder', '0');
        i.setAttribute('scrolling', 'yes');
        i.setAttribute('allow', 'camera;microphone');
        i.setAttribute('id', id);
        i.style.height = '340px';
        i.setAttribute('width', '100%');
        i.title = `Send us a Testimonial`
        embed.appendChild(i);
        i.onload = function() {
          iFrameResize(
            {},
            `#${id}`
          );
        };
      }
    });
  };
  var iframeId = 'shoutout-iframe-resizer';
  var iframeScript = document.getElementById(iframeId);
  if (iframeScript === null || !iframeScript.getAttribute('data-loaded')) {
    var script_is_loaded = false;
    var cs = document.currentScript;
    var s = doc.createElement(el);
    s.setAttribute('id', iframeId);
    s.src = 'https://shoutout.io/static/website/js/iframeResizer.min.js';
    s.onload = s.onreadystatechange = function () {
      var rs = this.readyState;
      if (script_is_loaded) return;
      if (rs) if (rs != 'complete') if (rs != 'loaded') return;
      script_is_loaded = true;
      s.setAttribute('data-loaded', true);
      try {
        loadEmbeds();
      } catch (e) {}
    };
    var scr = doc.getElementsByTagName(el)[0],
      par = scr.parentNode;
    par.insertBefore(s, scr);
  } else {
    loadEmbeds();
  }
})(document, 'script');